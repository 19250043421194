import React from 'react'
import Helmet from 'react-helmet'
import injectSheet from 'react-jss';
import { graphql, withPrefix } from "gatsby"

import { Match } from "@reach/router";

//import spinner from '../assets/ajax-loader.gif';
import Box from "../components/Box";
import Layout from "../containers/layout";
import Spinner from "../components/Spinner";

const styles = theme => ({
    root: {
        position: 'relative',
        flex: '1 1 auto',
    },
    iframe: {
//        background: 'url(' + spinner + ') no-repeat center center',
        width: 1,
        minWidth: '100%',
        minHeight: '300px',
        border: 0,
        position: 'relative',
        zIndex: 10,
    },
});

class Tickets extends React.Component {
    constructor(props){
        super(props);

        this.iFrameRef = React.createRef();
        this.receiveMessage = this.receiveMessage.bind(this);
    }


    componentDidMount() {
        window.addEventListener("message", this.receiveMessage, true);
        window.setTimeout(()=>{
            this.iFrameRef.current.contentWindow.addEventListener("message", this.receiveMessage, true);
        }, 100);
    }

//    shouldComponentUpdate(nextProps, nextState, nextContext) {
//        return false;
//    }

    componentWillUnmount() {
        this.iFrameRef.current.contentWindow.removeEventListener("message", this.receiveMessage);
    }

    receiveMessage(event) {
        if (event.origin !== "https://www.ticketcorner.ch")
            return;
        let current = this.iFrameRef.current;
        let childDoc = current.contentDocument || current.contentWindow.document;
        let tIFrame = childDoc.getElementsByTagName('iframe')[0];

        window.setTimeout(() => {
            current.scrolling = 'no';
            current.style.overflow = 'hidden';
            current.style.height = tIFrame.style.height;
        }, 150);
    }

    render() {
        const {classes, ticket, data} = this.props;

        let iFrame = (
            <noscript>
                JavaScript wird benötigt um Tickets zu bestellen.
            </noscript>
        );
        if(typeof window !== "undefined"){
            iFrame = (
                <Match path="/tickets/:ticket">
                    {
                        props => {
                            let path = props.match ? props.match.ticket.replace('/', ''): 'index.html';
//                            let prefetch = 'https://www.ticketcorner.ch/eventseries/2043636/?affiliate=VS2';
//                            <!--link rel="prefetch" href={prefetch} /-->
                            return (
                                <>
                                    <Helmet>
                                        <link rel="dns-prefetch" href="//ticketcorner.ch" />
                                        <link rel="preconnect" href="https://www.ticketcorner.ch" />
                                        <link rel="prefetch" href={'https://www.ticketcorner.ch/obj/nucleus/partner/iframe.js'} />
                                    </Helmet>
                                    <Spinner/>
                                    <iframe
                                        ref={this.iFrameRef}
                                        className={classes.iframe}
                                        src={withPrefix("/ticketcorner/"+path)}
                                    ></iframe>
                                </>
                            );
                        }
                    }
                </Match>
            );
        }

        return (
            <Layout pageTitle={"Ticket"}>
                <Box header="Tickets" className={'container'}>
                    <div className={classes.root}>
                        {iFrame}
                    </div>
                </Box>
                <div className="container">
                    <ul>
                        <li>FESTIVAL- UND TAGESPÄSSE<br/>
                            <div>Die Tagespässe gelten für folgende Veranstaltungen (Stehplätze):</div>
                            <ul>
                                <li>Mittwoch:<br/>
                                    Zwei Vorrunden nach Wahl.
                                </li>
                                <li>Donnerstag:<br/>
                                    Eine Vorrunde & ein Team Halbfinale nach Wahl.
                                </li>
                                <li>Freitag:<br/>
                                    Das Teamfinale sowie ein Halbfinale nach Wahl.
                                </li>
                                <li>Samstag:<br/>
                                    Das Einzelfinale sowie ein Halbfinale nach Wahl.
                                </li>
                            </ul>
                            <p>Die Festivalpässe gelten für alle Vorrunden, Halbfinale und Finale (Einzel & Team)<br/>
                                Diese Festivalpässe müssen an der Abendkasse gegen einen Kontrollbändel eingetauscht werden.</p>
                        </li>
                        <li>ROLLSTUHLPLÄTZE / BEGLEITUNG:<br/>
                            Rollstuhlfahrer & Begleitpersonen erhalten keine Ermässigung auf den Ticketpreis.
                        </li>
                        <li>EMPFEHLUNG:<br/>
                            Kinder und Jugendliche bis 14 Jahren haben nur in Begleitung Erwachsener Zutritt. Der Veranstalter lehnt bei Zuwiderhandlung jegliche Verantwortung und/oder Haftung ab.<br/>
                            Ticketcorner ist exklusiver Billetverkäufer und warnt davor, Tickets auf anderen Online-Plattformen zu erwerben. Vor dem Einlass in die Veranstaltungsstätte werden die Tickets elektronisch gescannt, Inhaber von gefälschten Tickets haben keinen Zutritt.
                        </li>
                        <li>MIT DEM TRAM FAHREN UND DABEI SPAREN!<br/>
                            Dieses Ticket gilt als Fahrausweis. Gültigkeit des Fahrausweises: 2. Klasse – Zone 110 des Zürcher Verkehrsverbundes.<br/>
                            Der Fahrausweis gilt am jeweiligen Veranstaltungstag während der Veranstaltungsdauer und nur zusammen mit dem Eintrittsticket der jeweiligen Veranstaltung. Der Nacht- sowie der Schiffszuschlag sind nicht inbegriffen.
                        </li>
                    </ul>
                </div>
            </Layout>
        );
    }
}


export default injectSheet(styles())(Tickets);

export const query = graphql`
    query TicketPageQuery {
        events: allWordpressWpEvents(
            filter: {content: {ne: ""}},
        ){
            edges{
                event: node{
                    slug
                    custom_fields{
                        link: wpcf_link
                    }
                }
            }
        }
    }
`;